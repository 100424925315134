<template>
  <div class="popover" v-if="selected">
    <div class="content">
      <!-- <div class="screenshots"> -->
      <button class="close" @click="closeWindow"><Fa fa="close" /></button>
      <VueAgile
        :navButtons="true"
        :centerMode="true"
        :slidesToShow="1"
        :infinite="false"
        class="imgs"
      >
        <img
          v-for="img in selected.images"
          :key="img.title"
          :src="img.img"
          alt=""
          class="slide"
        />
      </VueAgile>
      <!-- </div> -->
      <div class="info">
        <h3>{{ selected.titel }}</h3>
        <div class="tags">
          <span v-for="tag in selected.tags" :key="tag">{{ tag }}</span>
        </div>
        <!-- <span>
          {{ selected.description }}
        </span> -->
        <div class="tags links">
          <a
            :href="selected.demo"
            target="_blank"
            v-if="selected.demo.length > 1"
            >Demo</a
          >
          <a :href="selected.source" target="_blank">Source</a>
        </div>
        <div class="description"></div>
      </div>
    </div>
    <div class="background" @click="closeWindow"></div>
  </div>
</template>

<script>
import Fa from './globals/Fa.vue';
import { VueAgile } from 'vue-agile';

export default {
  components: {
    Fa,
    VueAgile,
  },
  props: {
    selected: Object,
    closeWindow: Function,
  },
};
</script>
<style lang="scss">
button.agile__nav-button[disabled='disabled'] {
  color: gray;
}
.agile__actions {
  padding: 0rem 0.7rem;
}
.agile__dot button {
  // width: 2rem;
  // height: 2rem;
  border: 0;
  width: 0.3rem;
  height: 0.3rem;
  color: red;
}
button.agile__nav-button {
  width: 2rem;
  height: 2rem;
  border: 0;
}
.agile {
  z-index: -1;
}
.agile img {
  object-fit: contain;
}
.agile {
  button {
    background: transparent;
    padding: 0;
    color: white;
    cursor: pointer;
  }
}
</style>
<style lang="scss" scoped>
.popover {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1001;
  overflow: auto;
  top: 0;
  display: flex;
  .background {
    background: hsl(240deg 5% 6% / 85%);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
    top: 0;
  }

  .content {
    background: #1d1e21;
    max-width: 600px;
    // height: 100%;
    // overflow: scroll;
    margin: auto;
    width: -webkit-fill-available;
    position: relative;
    border-radius: 0.7rem;
    z-index: 100;
  }
  .info {
    padding: 0 1.3rem 2rem 1.3rem;
    h3 {
      grid-area: txt;
      font-weight: 500;
      font-size: 1.7rem;
      line-height: 1.4;
      margin-bottom: 0rem;
      margin-top: 0;
    }

    .tags {
      display: flex;
      gap: 0.7rem;
      font-weight: 500;
      font-size: 0.8rem;
      color: var(--txt-med);
    }
    .tags.links {
      a {
        color: white;
        text-decoration: none;
        margin-top: 0.6rem;
      }
    }
  }
  .info > span {
    display: block;
    margin-top: 1rem;
    font-weight: 500;
    font-size: 0.8rem;
    color: var(--txt-med);
  }
  .screenshots {
    padding: 1.3rem;
    img {
      max-height: 400px;
      max-width: 100%;
      margin: auto;
    }
  }
  .close {
    background: transparent;
    border: 0;
    color: white;
    padding: 1rem;
    float: right;
    cursor: pointer;
    z-index: 100;
  }
}
</style>
